<template>
  <v-container>
    <v-row>
      <v-col class="py-1">
        <div class="d-flex mx-md-6 mt-4 mt-md-6">
          <p class="page__title">
            Quotation <v-icon color="#8f96a1">mdi-chevron-right</v-icon>
            <span style="color: #8f96a1">{{
              $route.query.edit
                ? "Edit"
                : $route.query.delivery_note
                  ? "Delivery Note"
                  : "Create"
            }}</span>
          </p>
          <v-spacer></v-spacer>
          <v-btn @click="$router.go(-1)" text rounded large v-if="$vuetify.breakpoint.mdAndUp">
            <v-icon left>mdi-chevron-left</v-icon> Back
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row class="my-md-6">
      <v-col cols="12" class="py-1">
        <div class="pContainer">
          <div class="d-flex justify-center align-center flex-column px-md-10" :style="{
            width: `${$vuetify.breakpoint.mdAndDown ? '90%' : '70%'}`,
          }">
            <div class="d-flex align-center justify-end actionTxt py-1" v-if="showQuoteSettings">
              <v-icon color="primary" small>mdi-cog-outline</v-icon>
              <span class="text-decoration-underline">Quote settings</span>
            </div>
            <div class="d-flex justify-center align-center" style="height: 100vh; width: 100%" v-if="loadingPage">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>
            <div class="pContainer__card pa-4 pa-md-8 mb-6" v-else>
              <div>
                <div class="d-flex align-center mb-4">
                  <h5 class="cardTitle text-truncate">
                    {{
                      $route.query.edit
                        ? quoteDetails.label
                        : $route.query.delivery_note
                          ? "Delivery Note"
                          : "New Quote"
                    }}
                  </h5>
                  <span v-if="$route.query.delivery_note">
                    <span class="pill-one">{{ quoteDetails.rfq }}</span>
                    <span class="pill-two">
                      {{ quoteDetails.counterparty.company }}
                    </span></span>
                </div>

                <template>
                  <v-progress-linear color="#5b67ba" background-color="#f4f5fa"
                    :value="loadingPercent"></v-progress-linear>
                </template>
              </div>
              <v-form ref="createNewQuoteOne" v-if="currentStep === 0">
                <div class="px-4 px-md-10 py-2 py-md-6">
                  <div class="mt-4">
                    <v-row>
                      <v-col cols="12" class="pa-2">
                        <v-label class="fieldLabel">Name/Label</v-label>
                        <v-text-field :rules="requiredRule" v-model="quoteDetails.label" outlined hide-details="auto"
                          background-color="#f6f8f8" height="54px" placeholder="Enter a label to identify this quote"
                          class="custom-outline">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="mt-2">
                    <v-row>
                      <v-col cols="12" md="6" class="pa-2">
                        <v-label class="fieldLabel">Date of Issue</v-label>
                        <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                          min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field :rules="requiredRule" v-model="quoteDetails.dateOfIssue" hide-details="auto"
                              background-color="#f6f8f8" height="54px" outlined v-bind="attrs" v-on="on"
                              placeholder="Choose"></v-text-field>
                          </template>
                          <v-date-picker v-model="quoteDetails.dateOfIssue" @input="menu = false"
                            :min="getCurrentDate()" no-title scrollable>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <!-- <pre>{{ quoteDetails.counterparty }}</pre> -->
                      <v-col cols="12" md="6" class="pa-2">
                        <v-label class="fieldLabel">Counterparty</v-label>
                        <v-select :rules="requiredRule" v-model="quoteDetails.counterparty" :items="customerArray"
                          item-text="company" :return-object="true" outlined hide-details="auto" height="54px"
                          background-color="#f6f8f8" append-icon="mdi-magnify"
                          :menu-props="{ searchable: true, lazy: true }">
                        </v-select>
                        <div class="d-flex justify-space-between align-center">
                          <span style="font-size: 12px; color: #9e9e9e">customer</span>
                          <span @click="handleAddCustomer" style="
                              font-size: 12px;
                              text-decoration: underline;
                              color: rgba(91, 103, 186, 1);
                              cursor: pointer;
                            ">+ add customer</span>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="mt-2" v-if="$route.query.delivery_note">
                    <v-row>
                      <v-col cols="12" md="6" class="pa-2">
                        <v-label class="fieldLabel">Client PO</v-label>
                        <v-text-field :rules="requiredRule" v-model="clientpo" outlined hide-details="auto"
                          background-color="#f6f8f8" height="54px" placeholder="Client PO no.">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" class="pa-2">
                        <v-label class="fieldLabel">Our PO</v-label>
                        <v-text-field :rules="requiredRule" v-model="ourpo" outlined hide-details="auto"
                          background-color="#f6f8f8" height="54px" placeholder="Our PO no.">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="mt-2">
                    <!-- <pre>{{ $appCurrency }}</pre> -->
                    <v-row>
                      <v-col cols="12" md="6" class="pa-2">
                        <v-label class="fieldLabel">Currency</v-label>
                        <v-select :rules="requiredRule" v-model="quoteDetails.currency" :items="currencyItems" outlined
                          hide-details="auto" height="54px" background-color="#f6f8f8">
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="6" class="pa-2">
                        <v-label class="fieldLabel">Status</v-label>
                        <v-select v-model="quoteDetails.status" :items="statusOption" item-text="text"
                          item-value="value" height="54px" outlined hide-details="auto" background-color="#f6f8f8">
                        </v-select>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="mt-2" v-if="$route.query.delivery_note">
                    <v-row>
                      <v-col cols="12" md="6" class="pa-2">
                        <v-label class="fieldLabel">Waybill Number</v-label>
                        <v-text-field :rules="requiredRule" v-model="waybillnumber" outlined hide-details="auto"
                          background-color="#f6f8f8" height="54px" placeholder="Waybill no.">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" class="pa-2">
                        <v-label class="fieldLabel">Delivery Address</v-label>
                        <v-text-field :rules="requiredRule" v-model="deliveryaddress" outlined hide-details="auto"
                          background-color="#f6f8f8" height="54px" placeholder="Delivery address">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="mt-2">
                    <v-row>
                      <v-col cols="12" md="6" class="pa-2">
                        <v-label class="fieldLabel">RFQ</v-label>
                        <v-text-field :rules="requiredRule" v-model="quoteDetails.rfq" outlined hide-details="auto"
                          background-color="#f6f8f8" height="54px" placeholder="RFQ no.">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" class="pa-2">
                        <v-label class="fieldLabel">Price/Validity</v-label>
                        <v-menu v-model="menu2" :close-on-content-click="false" transition="scale-transition"
                          min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="selectedPriceValidityDate" hide-details="auto"
                              background-color="#f6f8f8" height="54px" outlined v-bind="attrs" v-on="on"
                              placeholder="Choose" @click:append="menu2 = !menu2"></v-text-field>
                          </template>
                          <v-list>
                            <v-list-item v-for="(option, index) in dropdownOptions" :key="index"
                              @click="selectOption(option)">
                              <v-list-item-title>{{
                                option
                              }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                        <v-date-picker v-if="selectedPriceValidityDate === 'Custom Date'" v-model="customDate"
                          @input="setCustomDate" no-title scrollable :min="getCurrentDate()" @change="menu2 = false">
                        </v-date-picker>
                        <div class="d-flex justify-space-between align-center">
                          <span style="font-size: 12px; color: #9e9e9e">How Long?</span>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="mt-2">
                    <v-row>
                      <v-col cols="12" md="6" class="pa-2">
                        <v-label class="fieldLabel">Delivery Terms</v-label>
                        <v-text-field v-model="quoteDetails.deliveryTerms" height="54px" outlined hide-details="auto"
                          background-color="#f6f8f8" placeholder="Terms">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" class="pa-2">
                        <v-label class="fieldLabel">VAT</v-label>
                        <v-select :rules="requiredRule" v-model="quoteDetails.vat" :items="vatOption" height="54px"
                          item-text="text" item-value="value" outlined hide-details="auto" background-color="#f6f8f8">
                        </v-select>
                        <div class="d-flex justify-space-between align-center">
                          <span style="font-size: 12px; color: #9e9e9e">Include/Exclude</span>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="mt-2">
                    <v-row>
                      <v-col cols="6" class="pa-2">
                        <v-label class="fieldLabel">Attention</v-label>
                        <v-text-field :rules="requiredRule" v-model="quoteDetails.attention" height="54px" outlined
                          hide-details="auto" background-color="#f6f8f8" placeholder="Attention">
                        </v-text-field>
                      </v-col>
                      <v-col cols="6" class="pa-2">
                        <v-label class="fieldLabel">Other terms</v-label>
                        <v-select v-model="quoteDetails.otherterms" :items="otherTermsOptions" item-text="text"
                          item-value="value" height="54px" outlined hide-details="auto" background-color="#f6f8f8">
                        </v-select>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="mt-2" v-if="quoteDetails.otherterms === 'others'">
                    <v-row>
                      <v-col cols="12" class="pa-2">
                        <v-label class="fieldLabel">if others(custom) was selected above, indicate custom
                          terms</v-label>
                        <v-text-field :rules="customTermsRules" v-model="customTermsValue" height="54px" outlined
                          hide-details="auto" background-color="#f6f8f8" placeholder="Custom terms">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <div class="py-4">
                  <hr />
                </div>
                <div class="d-flex align-center justify-end px-3 px-md-8">
                  <v-btn @click="moveToNext" color="primary" :width="$vuetify.breakpoint.smAndDown ? '100%' : '121'"
                    height="45">
                    <simple-line-icons icon="arrow-right" color="#FFFFFF" class="simpleLineIcon" no-svg />
                    <span class="text-capitalize pl-3" style="color: #ffffff">Next</span>
                  </v-btn>
                </div>
              </v-form>
              <v-form ref="createNewQuoteTwo" v-if="currentStep === 1">
                <div class="py-md-6" style="max-width: 700px; margin: auto">
                  <p class="formInfo" v-if="$route.query.delivery_note">
                    Enter the line items for this delivery note below.
                  </p>
                  <p class="formInfo" v-else>
                    Enter the line items for this quote below. You can also add
                    on <strong>more columns</strong>, by toggling the More
                    Columns switch
                  </p>
                </div>
                <div class="d-flex align-center" v-if="!$route.query.delivery_note">
                  <v-label class="fieldLabel">More Columns</v-label>
                  <BaseSwitch class="ml-2" v-model="showMoreColumns" :switchWidth="'25px'" :switchHeight="'10px'"
                    :handleWidth="'16px'" :handleHeight="'16px'" :switchColor="'#ccc'" :switchActiveColor="'#7ac943'"
                    :switchHoverColor="'#ddd'" />
                </div>

                <div class="pt-md-2 pb-md-6">
                  <div class="scroll-container py-2" ref="quoteItem" v-for="(item, j) in quoteDetails.lineItems"
                    :key="j">
                    <v-icon @click.prevent="removeItem(j)" tag="button" dark color="primary" small class="mt-8 ml-1"
                      v-if="
                        quoteDetails.lineItems &&
                        quoteDetails.lineItems.length > 1
                      ">mdi-trash-can</v-icon>
                    <fieldItemContainer
                      v-for="(field, i) in $route.query.delivery_note ? filteredLineItems(Object.keys(item).slice(0, 2)) : filteredLineItems(Object.keys(item))"
                      :key="i" :width="getStyle(field).width" :style="{ minWidth: getStyle(field).minWidth }">

                      <div class="d-flex align-center justify-between py-3">
                        <v-label class="fieldLabel"><strong>{{ getStyle(field).label }}</strong></v-label>
                        <v-spacer v-if="getStyle(field).hasSwitch"></v-spacer>
                        <BaseSwitch v-if="getStyle(field).hasSwitch" :switchColor="'#ccc'"
                          :switchActiveColor="'#7ac943'" :switchHoverColor="'#ddd'"
                          v-model="getStyle(field).switchValue" />
                      </div>
                      <v-text-field height="54" v-model="quoteDetails.lineItems[j][field]" class="small-placeholder"
                        outlined hide-details="auto" background-color="#f6f8f8"
                        :readonly="field === 'total_price' ? true : false" :rules="getValidationRules(field)"
                        @blur="formatValues" :placeholder="getStyle(field).placeholder"></v-text-field>
                    </fieldItemContainer>
                  </div>
                  <div class="d-flex align-center justify-end pb-0 px-md-10">
                    <p class="linkText" @click.prevent="addNewItem">
                      +new item
                    </p>
                  </div>
                </div>
                <div class="d-flex align-center justify-end px-3 px-md-8 pb-2">
                  <div class="
                      grandTotalContainer
                      d-flex
                      justify-center
                      flex-column
                      pa-6
                    ">
                    <p class="grandTotalTitle">Total amount</p>
                    <p class="grandTotalValue">
                      {{
                        subTotal | currency({ symbol: quoteDetails.currency })
                      }}
                    </p>
                  </div>
                </div>
                <div class="block d-md-flex justify-end align-center py-3 px-md-8">
                  <v-btn @click="stepBack" color="#F4F5F6" elevation="0"
                    :width="$vuetify.breakpoint.smAndDown ? '100%' : ''" class="px-6 mr-5 py-6 mt-3">
                    <simple-line-icons icon="arrow-left" color="#19283d" class="simpleLineIcon" no-svg />
                    <span class="primary--text text-capitalize pl-3">Back</span>
                  </v-btn>

                  <v-btn v-if="$route.query.edit == 'true' && $route.params.id" elevation="0"
                    :width="$vuetify.breakpoint.smAndDown ? '100%' : ''" class="text-capitalize px-6 mr-5 py-6 mt-3"
                    @click="createNewCopyQuote" :loading="isSavingNew" color="#2bd4ae">
                    <simple-line-icons icon="arrow-right" color="#19283d" class="simpleLineIcon font-weight-bold"
                      no-svg />
                    <span class="text-capitalize pl-3 font-weight-medium" style="color: #19283d">Save New</span>
                  </v-btn>

                  <v-btn @click="createQuote" :loading="isCreatingQuote" color="primary"
                    :width="$vuetify.breakpoint.smAndDown ? '100%' : ''" class="text-capitalize px-6 py-6 mt-3">
                    <simple-line-icons icon="arrow-right" color="#FFFFFF" class="simpleLineIcon" no-svg />
                    <span class="text-capitalize pl-3" style="color: #ffffff">{{
                      saveText
                    }}</span>
                  </v-btn>
                </div>
              </v-form>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <AddCustomer ref="AddCustomer" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import fieldItemContainer from "@/pages/quotes/components/fieldItemContainer.vue";
import BaseSwitch from "@/components/BaseSwitchComponent.vue";
import { formatCurrencyAmount } from "@/mixins/amountFormatter.js";
import quoteMixins from "@/mixins/quote.js";
import currencyFilter from "@/mixins/currencyFilter";

import AddCustomer from "@/includes/overlays/ContactsMenuModals/AddCustomer.vue";
// import { th } from 'date-fns/locale';
export default {
  name: "createQuote",
  components: { fieldItemContainer, BaseSwitch, AddCustomer },
  data() {
    return {
      loadingPercent: 50,
      loadingPage: false,
      currentStep: 0,
      isEdit: false,
      isCreatingQuote: false,
      isSavingNew: false,
      showMoreColumns: false,
      showQuoteSettings: false,
      menu: false,
      menu2: false,
      selectedPriceValidityDate: null,
      customDateMenu: false,
      customDate: null,
      customTermsValue: "",
      requiredRule: [
        (v) => !!v || "This field is required", // Check if the value is not empty
      ],
      waybillnumber: "",
      clientpo: "",
      ourpo: "",
      deliveryaddress: "",


    };
  },

  computed: {
    // map getters
    ...mapGetters({
      customerArray: "contacts/getCustomersCompanyName",
      single_customer: "contacts/getCustomer",
      singleQuote: "quotes/getSingleQuote",
    }),

    // the button text to return based on the mode( save/edit )
    saveText() {
      return this.$route.query.edit == "true" && this.$route.params.id
        ? "Save Update"
        : this.$route.query.delivery_note == "true"
          ? "Generate Delivery Note"
          : "Save";
    },

    singleCustomer() {
      if (!this.single_customer) {
        return;
      }

      return {
        id: this.single_customer?.data?.hypn_id,
        company: this.single_customer?.data?.company?.company_name,
        entity_type: "customer",
      };
    },

    /**
     * a selected rule that only applies if the quoteDetails
     * otherterms value is equal to "others"
     */

    customTermsRules() {
      return this.quoteDetails.otherterms === "others" &&
        this.customTermsValue === ""
        ? [(v) => !!v || "Please, provide the custom terms"]
        : [];
    },

    // computed prop that sums up all the total prices in the line items
    subTotal() {
      let total = 0;

      this.quoteDetails.lineItems.forEach((item) => {
        let tp = this.convertStringToFloat(item.total_price);
        total += tp;
      });
      return total;
    },
    // calculate the total plus 7.5% tax
    grandTotalPlusTax() {
      return this.subTotal + this.subTotal * 0.075;
    },

    createQuotePayload() {
      return {
        label: this.quoteDetails.label,
        currency: this.quoteDetails.currency,
        issued: this.quoteDetails.dateOfIssue,
        price_validity: this.quoteDetails.priceValidity,
        delivery_terms: this.quoteDetails.deliveryTerms,
        taxed: this.quoteDetails.vat,
        other_terms: this.quoteDetails.otherterms,
        status: this.quoteDetails.status,
        rfq: this.quoteDetails.rfq,
        counterparty: this.quoteDetails.counterparty.id,
        counterparty_type: this.quoteDetails.counterparty.entity_type,
        lines: this.quoteDetails.lineItems.map((item) => ({
          description: item.description,
          quantity: item.qty,
          uom: item.uom,
          price: {
            unit: item.unit_price,
            total: item.total_price,
            oem: item.oem_price,
          },
          lead_time: item.lead_time,
          material_no: item.material_no,
        })),
        total: this.quoteDetails.vat
          ? this.grandTotalPlusTax.toString()
          : this.subTotal.toString(),
        subtotal: this.subTotal.toString(),
        attention: this.quoteDetails.attention,
      };
    },

    //generate delivery note payload
    generateDeliveryNotePayload() {
      return {
        id: this.$orgHypnId,
        send: true,
        waybill: {
          sender_po: this.ourpo,
          client_po: this.clientpo,
          delivery: {
            address: this.deliveryaddress,
            city: "",
            zip: "",
            country: ""

          },
          information: {
            number: "",
            date: "",
          },
          products: this.quoteDetails.lineItems.map((item) => ({
            description: item.description,
            quantity: item.qty,
          }))


        }
      }
    }
  },
  mixins: [currencyFilter, quoteMixins],
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),

    async getAllCustomersAndVendors() {
      try {
        await this.$store.dispatch("contacts/fetchAllCustomers", this.$orgId);
      } catch (error) {
        console.log(error);
      }
    },
    selectOption(option) {
      if (option !== "Custom Date") {
        this.selectedPriceValidityDate = option;
        const days = parseInt(option.split(" ")[0]);
        const dueDate = new Date();
        dueDate.setDate(dueDate.getDate() + days);
        this.quoteDetails.priceValidity = dueDate.toISOString().substr(0, 10);
        this.menu2 = false;
      } else {
        this.selectedPriceValidityDate = option; // Set selected option to 'Custom Date'
        this.menu2 = false; // Hide the dropdown list
        // Reset customDate to null when 'Custom Date' is selected from the dropdown
        this.customDate = null;
      }
    },

    setCustomDate() {
      if (this.customDate) {
        // Calculate days from the current date when a custom date is selected
        const currentDate = new Date();
        const selectedDate = new Date(this.customDate);
        const differenceInTime = selectedDate.getTime() - currentDate.getTime();
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
        this.selectedPriceValidityDate = `${Math.floor(differenceInDays)} Days`;
        // Assign the selected date to the invoice due date
        this.quoteDetails.priceValidity = this.customDate;
        this.menu2 = false; // Hide the date picker after selection
      }
    },
    getCurrentDate() {
      const date = new Date();
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    // handle moving to the next step of the form
    moveToNext() {
      // console.log(JSON.stringify(this.quoteDetails, null, 2));
      if (this.$refs.createNewQuoteOne.validate()) {
        this.currentStep++;
        this.loadingPercent = 100;
      }
    },

    // go back one step
    stepBack() {
      this.currentStep--;
      this.loadingPercent = 50;
    },

    // get a single quote

    async getQuote(id) {
      this.loadingPage = true;
      try {
        this.loadingPercent = 50;

        const payload = {
          organization_hypnID: this.$orgHypnId,
          quoteHypnId: id,
        };

        const { data } = await this.$store.dispatch("quotes/getQuote", payload);

        if (data.status !== "success") {
          console.error("Failed to fetch quote:", data.error);
          return;
        }

        const quoteData = data.data;
        const { quoteDetails } = this;
        const cusId = quoteData.counterparty;
        await this.$store.dispatch("contacts/getSingleCustomer", cusId);
        quoteDetails.label = quoteData.label;
        quoteDetails.dateOfIssue = quoteData.issued;
        quoteDetails.currency = quoteData.currency;
        quoteDetails.counterparty = this.singleCustomer;
        quoteDetails.attention = quoteData.attention;
        quoteDetails.deliveryTerms = quoteData.delivery_terms;
        quoteDetails.vat = quoteData.taxed
          ? quoteData.taxed.toString()
          : "true";
        quoteDetails.otherterms = quoteData.other_terms;
        quoteDetails.status = quoteData.status;
        quoteDetails.rfq = quoteData.rfq;
        quoteDetails.lineItems = quoteData.lines.map((item) => ({
          description: item.description,
          qty: item.quantity,
          uom: item.uom,
          unit_price: item.price.unit,
          total_price: item.price.total,
          oem_price: item.price.oem,
          lead_time: item.lead_time,
          material_no: item.material_no,
        }));

        quoteDetails.priceValidity = quoteData.price_validty;

        this.showToast({
          sclass: "success",
          show: true,
          message: "Quote fetched successfully",
          timeout: 3000,
        });
      } catch (error) {
        this.showToast({
          sclass: "error",
          show: true,
          message: error.message || "An error occurred",
          timeout: 3000,
        });
      } finally {
        this.loadingPage = false;
      }
    },

    // get styles
    getStyle(key) {
      const result = this.itemStyles.find((style) => style.key === key);
      return result;
    },
    filteredLineItems(array) {
      // console.log(array);
      if (!this.showMoreColumns) {
        return array.slice(0, 5);
      }
      return array;
    },

    addNewItem() {
      this.quoteDetails.lineItems.push({
        description: "",
        qty: null,
        uom: "",
        unit_price: null,
        total_price: null,
        oem_price: null,
        lead_time: "",
        material_no: "",
      });
    },

    removeItem(index) {
      // check if the item is the first item and if the second item has empty values, if so return early

      if (
        index === 0 &&
        this.quoteDetails.lineItems.length > 1 &&
        !this.quoteDetails.lineItems[1].description
      ) {
        // show toast message
        this.showToast({
          sclass: "error",
          show: true,
          message: "Line item for this quote cannot be empty",
          timeout: 3000,
        });
        return;
      }
      this.quoteDetails.lineItems.splice(index, 1);
    },
    formatValues() {
      const lineItems = this.quoteDetails.lineItems;

      lineItems.forEach((item) => {
        // Check if qty and unit_price are valid numbers
        if (!isNaN(item.qty) && !isNaN(item.unit_price)) {
          // Format unit_price
          item.unit_price = formatCurrencyAmount(item.unit_price);

          // Calculate and format total_price
          item.total_price = (item.qty * item.unit_price).toFixed(2);
          item.total_price = formatCurrencyAmount(item.total_price);

          // Format oem_price if it has a value
          if (item.oem_price !== null) {
            item.oem_price = formatCurrencyAmount(item.oem_price);
          }
        }
      });
    },

    // convert string amounts to float
    convertStringToFloat(string) {
      if (string === null) {
        return null;
      }
      return parseFloat(string.replace(/,/g, ""));
    },

    // convert float number to string with commas
    convertFloatToString(float) {
      if (float === null) {
        return null;
      }
      return float
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    // validation function for createNewQuoteTwo
    getValidationRules(field) {
      const rules = [];

      if (
        field === "qty" ||
        field === "unit_price" ||
        field === "oem_price" ||
        field === "total_price"
      ) {
        rules.push((value) => !!value || "Field is required");
        rules.push(
          (value) =>
            /^(\d{1,3}(,\d{3})*|\d+)(\.\d{1,2})?$/.test(value) ||
            "Enter a valid amount"
        );
      }

      if (field === "description" || field === "uom") {
        rules.push((value) => !!value || "Field is required");
      }

      return rules;
    },

    handleAddCustomer() {
      this.$refs.AddCustomer.show(true);
    },

    // get all quotes
    async getQuotes() {
      try {
        const data = await this.$store.dispatch(
          "quotes/getAllQuotes",
          this.$orgHypnId
        );
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },

    async createQuote() {
      if (
        this.createQuotePayload.lineItems &&
        this.createQuotePayload.lineItems.length === 0
      ) {
        this.showToast({
          sclass: "error",
          show: true,
          message: "Please add at least one item to the quote",
          timeout: 3000,
        });

        return;
      }

      if (this.$refs.createNewQuoteTwo.validate()) {
        if (this.$route.query.edit == "true" && this.$route.params.id) {
          this.isCreatingQuote = true;
          const payload = {
            organization_hypnID: this.$orgHypnId,
            payload: this.createQuotePayload,
            quoteHypnId: this.$route.params.id,
          };
          try {
            const data = await this.$store.dispatch(
              "quotes/updateQuote",
              payload
            );
            this.getQuotes();
            this.$router.push({
              name: "previewQuote",
              params: { id: data.data.hypnID },
            });
            this.showToast({
              sclass: "success",
              show: true,
              message: "Quote updated successfully",
              timeout: 3000,
            });
          } catch (error) {
            this.showToast({
              sclass: "error",
              show: true,
              message: error.message || "An error occurred",
              timeout: 3000,
            });
          } finally {
            this.isCreatingQuote = false;
          }
        } else if (
          this.$route.query.delivery_note == "true" &&
          this.$route.params.id
        ) {
          this.isCreatingQuote = true;
          const payload = {
            organization_hypnID: this.$orgHypnId,
            payload: this.generateDeliveryNotePayload
          };
          try {
          const res =   await this.$store.dispatch(
              "quotes/generateDeliveryNote",
              payload);
              
              console.log(res);
              
              //open file in a new tab
              window.open(res.data.file, "_blank");
            this.showToast({
              sclass: "success",
              show: true,
              message: "Delivery note generated successfully",
              timeout: 3000,
            });
          } catch (error) {
            this.showToast({
              sclass: "error",
              show: true,
              message: error.message || "An error occurred",
              timeout: 3000,
            });

          } finally {
            this.isCreatingQuote = false;
          }
        } else {
          this.isCreatingQuote = true;
          const payload = {
            organization_hypnID: this.$orgHypnId,
            payload: this.createQuotePayload,
          };
          try {
            const data = await this.$store.dispatch(
              "quotes/createNewQuote",
              payload
            );
            // console.log("DATA: ", data)
            this.getQuotes();

            this.$router.push({
              name: "previewQuote",
              params: { id: data.data.id },
            });

            this.showToast({
              sclass: "success",
              show: true,
              message: "Quote created successfully",
              timeout: 3000,
            });
          } catch (error) {
            this.showToast({
              sclass: "error",
              show: true,
              message: error.message || "An error occurred",
              timeout: 3000,
            });
          } finally {
            this.isCreatingQuote = false;
          }
        }
      }
    },
    async createNewCopyQuote() {
      if (
        this.createQuotePayload.lineItems &&
        this.createQuotePayload.lineItems.length === 0
      ) {
        this.showToast({
          sclass: "error",
          show: true,
          message: "Please add at least one item to the quote",
          timeout: 3000,
        });

        return;
      }

      if (this.$refs.createNewQuoteTwo.validate()) {
        this.isSavingNew = true;

        const payload = {
          organization_hypnID: this.$orgHypnId,
          payload: this.createQuotePayload,
        };
        try {
          const data = await this.$store.dispatch(
            "quotes/createNewQuote",
            payload
          );
          // console.log("DATA: ", data)
          this.getQuotes();

          this.$router.push({
            name: "previewQuote",
            params: { id: data.data.id },
          });

          this.showToast({
            sclass: "success",
            show: true,
            message: "New Copy created successfully",
            timeout: 3000,
          });
        } catch (error) {
          this.showToast({
            sclass: "error",
            show: true,
            message: error.message || "An error occurred",
            timeout: 3000,
          });
        } finally {
          this.isSavingNew = false;
        }
      }
    },
  },

  async mounted() {
    if (
      this.$route.query.edit == "true" ||
      (this.$route.query.delivery_note == "true" && this.$route.params.id)
    ) {
      await this.getQuote(this.$route.params.id);
    }
    if (this.$route.query.delivery_note == "true" && this.$route.params.id) {
      this.currentStep = 0;
    }
    this.getAllCustomersAndVendors();
    if (
      !this.$route.query.edit == "true" ||
      (!this.$route.query.delivery_note == "true" && !this.$route.params.id)
    ) {
      this.quoteDetails.currency = this.$appCurrency;
    }
  },
  watch: {
    "quoteDetails.lineItems": {
      handler: function (newVal) {
        newVal.forEach((item) => {
          const up = this.convertStringToFloat(item.unit_price);
          if (!isNaN(item.qty) && !isNaN(up)) {
            let total = item.qty * up;
            item.total_price = this.convertFloatToString(total);
          }
        });
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.page__title {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 39px;
}

// .v-input .search-field .v-input__slot:before,
// .v-input .search-field .v-input__slot:after {
//     border: none !important;
//     border-color: transparent !important;
// }

.v-menu__content {
  box-shadow: none;
}

i.sli-font {
  font-size: 12px;
  display: inline-block;
}

.material-icons {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #19283d;
}

.pContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // max-width: 1000px;

  &__card {
    background-color: #fff;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%),
      0px 6px 10px 0px rgb(0 0 0 / 3%), 0px 1px 18px 0px rgb(0 0 0 / 3%);
    width: 100%;
    height: auto;

    .cardTitle {
      font-family: "inter";
      font-size: 20px;
      font-weight: 700;
      color: var(--v-primary-base);
    }
  }

  .actionTxt {
    font-size: 12px;
    color: var(--v-primary-base);
    font-weight: 400;
  }
}

label {
  font-family: "inter";
  font-size: 14px;
  font-weight: 500;
  color: rgba(25, 40, 61, 0.8);
}

hr {
  border: 0.4px solid #e8ebec8c;
}

.scroll-container {
  width: 100%;
  height: auto;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;

  &::-webkit-scrollbar {
    width: 18px;
    height: 4px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    /* Change this to your desired track style */
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--v-primary-base);
    border-radius: 10px;
  }

  /* Add other scrollbar styles for different browsers if needed */

  label {
    font-family: "inter";
    font-size: 14px;
    font-weight: 500;
    color: rgba(25, 40, 61, 0.8);
  }

  .small-placeholder ::placeholder {
    font-size: 12px;
    /* Adjust this value to your desired smaller font size */
  }

  text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot {
    padding: 0 6px;
  }

  // .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot fieldset {
  //     color: #d9dee1;
  // }
}

.simpleLineIcon {
  font-family: simple-line-icons;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.formInfo {
  font-size: 12px;
  color: var(--v-primary-base);
  font-weight: 400;
  font-family: "inter";
}

@media only screen and (min-width: 600px) {
  .page__title {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
  }
}

@media only screen and (min-width: 768px) {

  /* For desktop: */
  .page__title {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
  }
}

.v-text-field--outlined .v-input__control {
  border-color: #d9dee1 !important;
}

.linkText {
  color: rgba(91, 103, 186, 1);
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 27.49px;
  // text-decoration: underline;
  margin-bottom: 0px;

  &:hover {
    font-weight: 800;
  }
}

.redlinkText {
  color: red;
  cursor: pointer;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 27.49px;
  // text-decoration: underline;
  margin-bottom: 0px;
}

.grandTotalContainer {
  width: 250px;
  height: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #f0f2f3;
  border: 1px solid #f0f2f3;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.grandTotalTitle {
  color: rgba(127, 145, 155, 1);
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14.52px;
  margin-bottom: 0px;
}

.grandTotalValue {
  color: var(--v-primary-base);
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29.05px;
  margin-bottom: 0px;
}

.pill-one {
  display: inline-block;
  padding: 4px 12px;
  border: transparent;
  margin: 0px 8px;
  font-size: 12px;
  font-weight: 700;
  background-color: #e5f9ff;
  color: #19283d;
  border-radius: 16px;
}

.pill-two {
  display: inline-block;
  padding: 4px 12px;
  border: transparent;
  // margin: 0px 8px;
  font-size: 12px;
  font-weight: 700;
  background-color: #d9e0f7;
  color: #19283d;
  border-radius: 16px;
}
</style>
